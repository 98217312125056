import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import AgentLogin from "../agent/AgentLoginPage";
import ForgetPassword from "../ForgetPassword";
import { FaHome } from "react-icons/fa";
import { MdOutlineVisibility, MdOutlineVisibilityOff } from "react-icons/md";
import { useClient } from "../../../context/UserAuthContext.jsx";
import '../../../styles/pages/authpage.css';

function ClientLoaginPage() {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [aciveContainer, setActiveContainer] = useState(true);
    const [agentLogin, setAgentLogin] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const { loginUser } = useClient();
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!email || !password) {
            setError("Please fill in all fields.");
            return;
        }
    
        try {
            const personExists = await loginUser(email, password);
            if (!personExists) {
                setError("No user found with this email.");
                return;
            }

            navigate("/");
        } catch (error) {
            setError(error.message); // Display the error message
            console.error("Error during sign-in:", error);
        }
    };
    

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <>
            {
                agentLogin
                    ? <AgentLogin setAgentLogin={setAgentLogin} setActiveContainer={setActiveContainer} aciveContainer={aciveContainer} />
                    : <div className="login-page">
                        <div className="login-blur">
                            <div className={`login-container ${aciveContainer ? 'activeCon' : 'closeCon'}`}>
                                <div className={`login-card`}>
                                    <a className="back-to-web" href='/' onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}><FaHome />&nbsp;{isHovered && <span className="tooltip-text">Home</span>}</a>
                                    <h2 className="login-title">Client Login</h2>
                                    <form onSubmit={handleSubmit}>
                                        <div className="form-group">
                                            <label htmlFor="email">Email</label>
                                            <input
                                                className="auth-input"
                                                type="email"
                                                id="client-email"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                                placeholder="Enter your email"
                                                required
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="password">Password</label>
                                            <div className="input-icon">
                                                <input
                                                    className="auth-input"
                                                    type={showPassword ? "text" : "password"}
                                                    id="password"
                                                    value={password}
                                                    onChange={(e) => setPassword(e.target.value)}
                                                    placeholder="Enter your password"
                                                    required
                                                />
                                                <button
                                                    type="button"
                                                    className="password-toggle-btn"
                                                    onClick={togglePasswordVisibility}
                                                >
                                                    {showPassword ? (
                                                        <MdOutlineVisibilityOff />
                                                    ) : (
                                                        <MdOutlineVisibility />
                                                    )}
                                                </button>
                                            </div>
                                        </div>
                                        <p className="forget-password" onClick={() => setActiveContainer(false)}>Forget Password</p>
                                        {error && <p className="error-message">{error}</p>}
                                        <button type="submit" className="login-button">Login</button>
                                    </form>
                                    <p className="signup-link">
                                        Don’t have an account? <a href="/signup">Sign Up</a>
                                    </p>
                                    <a href='/agent-login' className="forget-password" onClick={() => setAgentLogin(true)}>
                                        Agent Login
                                    </a>
                                </div>
                            </div>
                            <ForgetPassword aciveContainer={aciveContainer} setActiveContainer={setActiveContainer} />
                        </div>
                    </div>
            }
        </>
    );
};


export default ClientLoaginPage;