import React, { useState } from "react";
import {useNavigate } from "react-router-dom";
import { MdOutlineVisibility, MdOutlineVisibilityOff } from "react-icons/md";
import "../../../styles/pages/authpage.css";
import { useAdmin } from "../../../context/AdminAuthContext";

function AdminLoginPage() {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();
    const { loginAdmin } = useAdmin();

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!email || !password) {
            setError("Please fill in all fields.");
            return;
        }

        try {
            const personExists = await loginAdmin(email, password);
            if (!personExists) {
                setError("No admin found with this email.");
                return;
            }

            navigate("/admin-dashboard");
        } catch (error) {
            setError("An unknown error occurred.");
            console.error("Error during sign-in:", error);
        }
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <div className="admin-login">
            <div className="login-blur">
                <div className={`login-container`}>
                    <div className="login-card">
                        <h2 className="login-title">Admin Login</h2>
                        <form onSubmit={handleSubmit}>
                            <div className="form-group">
                                <label htmlFor="email">Email</label>
                                <input
                                    className="auth-input"
                                    type="email"
                                    id="admin-email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    placeholder="Enter your email"
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="password">Password</label>
                                <div className="input-icon">
                                    <input
                                        className="auth-input"
                                        type={showPassword ? "text" : "password"}
                                        id="password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        placeholder="Enter your password"
                                        required
                                    />
                                    <button
                                        type="button"
                                        className="password-toggle-btn"
                                        onClick={togglePasswordVisibility}
                                    >
                                        {showPassword ? (
                                            <MdOutlineVisibilityOff />
                                        ) : (
                                            <MdOutlineVisibility />
                                        )}
                                    </button>
                                </div>
                            </div>
                            {error && <p className="error-message">{error}</p>}
                            <button type="submit" className="login-button">Login</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default AdminLoginPage;