import React, { useState } from "react";
// import { useAdmin } from "../../context/AdminAuthContext";
// import { Navigate } from "react-router-dom";
import "../../styles/pages/admin.css";
import CreateAgent from "./CreateAgent";

function AdminPage() {

    const [activeButton, setActiveButton] = useState('Dashboard');

    // const { admin, logoutAdmin } = useAdmin();

    // if (!admin) {
    //     return <Navigate to="/admin-login" />;
    // }

    return (
        <div className="admin-page">
            <Slidebar activeButton={activeButton} onButtonClick={setActiveButton} />
            <BodyContent activeButton={activeButton} />
        </div>
    )
}

export default AdminPage;

export function Slidebar({ activeButton, onButtonClick }) {

    return (
        <nav className="sidebar">
            <ul className="silder-list">
                {["Dashboard", "Agents", "Tour", "Group Tours", "Slider"].map((button) => (
                    <li
                        key={button}
                        onClick={() => onButtonClick(button)}
                        className={`slider-li ${activeButton === button ? 'active' : ''}`}
                    >
                        <span>{button}</span>
                    </li>
                ))}
                <li
                    onClick={() => onButtonClick('Logout')}
                    className={`slider-li ${activeButton === 'Logout' ? 'active' : ''}`}
                >
                    <span>Logout</span>
                </li>
            </ul>
        </nav>
    )
}

export function BodyContent({ activeButton }) {
    return (
        <div className="dashboard-page">
            <h1>{activeButton}</h1>
            {activeButton === "Dashboard" && <p>Dashboard text</p>}
            {activeButton === "Agents" && <CreateAgent />}
            {activeButton === "Tour" && <p>Tour information and options.</p>}
            {activeButton === "Group Tours" && <p>Group tours management.</p>}
            {activeButton === "Slider" && <p>Edit your sliders here.</p>}
            {activeButton === "Logout" && ""}
        </div>
    )
}