import React, { useState, useRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import { FaSearch, FaPlus } from "react-icons/fa";
import { PiCarProfileLight } from "react-icons/pi";
import { MdCardTravel } from "react-icons/md";
import { HiOutlineUserGroup } from "react-icons/hi2";
import "react-datepicker/dist/react-datepicker.css";
import "../styles/components/advanceserchbar.css";
import { OtpVerification } from "../pages/auth/ForgetPassword";

function AdvancedSearchBar() {
    const [activeLink, setActiveLink] = useState("cabs");
    const navigate = useNavigate();
    const [activeOtp, setActiveOtp] = useState(false);
    const [correctOtp, setCorrectOtp] = useState("");
    const [msg, setMsg] = useState("");
    const [enteredOtp, setEnteredOtp] = useState("");
    const emailRef = useRef();

    // Function to generate a 6-digit OTP
    const generateOtp = () => {
        const range = "0123456789";
        let otpVal = "";
        for (let i = 0; i < 6; i++) {
            otpVal += range[Math.floor(Math.random() * 10)];
        }
        return otpVal;
    };

    const handleSendOtp = async (e) => {
        e.preventDefault(); // Prevent default form submission
        const otp = generateOtp();
        setCorrectOtp(otp);
        setActiveOtp(true);

        const email = emailRef.current.value;

        try {
            const response = await axios.post('http://localhost:5000/send-otp', { email, otp });

            if (response.status === 200) {
                alert('OTP sent successfully!');
            } else {
                alert('Failed to send OTP.');
            }
        } catch (error) {
            console.error('Error sending OTP:', error);
            alert('An error occurred.');
        }
    };

    const handleOtpSubmit = (e) => {
        e.preventDefault(); // Prevent default form submission
        if (enteredOtp === correctOtp) {
            setMsg("✅ OTP Verified Successfully!");
        } else {
            setMsg("❌ Invalid OTP. Please try again.");
        }
    };

    const handleClick = (e, link) => {
        e.preventDefault();
        setActiveLink(link);
    };

    return (
        <div className="slection-box">
            <ul className="selection-list">
                <li>
                    <a
                        className={`search-select-button ${activeLink === "cabs" ? "active" : ""}`}
                        href="cabs"
                        onClick={(e) => handleClick(e, "cabs")}
                    >
                        <span className="search-span-icons">
                            <PiCarProfileLight />
                        </span>
                        &nbsp;<span>Cabs</span>
                    </a>
                </li>
                <li>
                    <a
                        className={`search-select-button ${activeLink === "tour-packages" ? "active" : ""}`}
                        href="tour-packages"
                        onClick={(e) => handleClick(e, "tour-packages")}
                    >
                        <span className="search-span-icons">
                            <MdCardTravel />
                        </span>
                        &nbsp;<span>Tour&nbsp;Package</span>
                    </a>
                </li>
                <li>
                    <a
                        className={`search-select-button ${activeLink === "group-tour" ? "active" : ""}`}
                        href="group-tour"
                        onClick={(e) => handleClick(e, "group-tour")}
                    >
                        <span className="search-span-icons">
                            <HiOutlineUserGroup />
                        </span>
                        &nbsp;<span>Group&nbsp;Tour</span>
                    </a>
                </li>
            </ul>

            <div className="advanced-search-bar">
                {activeLink === "cabs" && (
                        <CabSerchBar
                            activeOtp={activeOtp}
                            correctOtp={correctOtp}
                            setEnteredOtp={setEnteredOtp}
                            emailRef={emailRef}
                            handleSendOtp={handleSendOtp}
                            handleOtpSubmit={handleOtpSubmit}
                            msg={msg}
                        />
                )}

                {activeLink === "tour-packages" && (
                    <TourSearchBar navigate={navigate} />
                )}

                {activeLink === "group-tour" && <GroupSerchBar navigate={navigate} />}
            </div>
        </div>
        // </div>
    );
}

export default AdvancedSearchBar;

export function CabSerchBar({emailRef, activeOtp, correctOtp, setEnteredOtp, msg, handleSendOtp, handleOtpSubmit }) {
    const [fromTerm, setFromTerm] = useState("");
    const [offerFrom, setOfferFrom] = useState("");
    const [toTerm, setToTerm] = useState("");
    const [destinations, setDestinations] = useState([""]);
    const [destination, setDestination] = useState("");
    const [startDate, setStartDate] = useState(null);
    const [passanger, setPassanger] = useState("Passanger");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [carOption, setCarOption] = useState("");
    const [selectedRedio, setSelectedRedio] = useState("one-way");
    const [time, setTime] = useState("");
    const [error, setError] = useState("");

    // Handle adding a new destination input
    const addDestination = () => {
        if (destinations.length < 10) {
            setDestinations([...destinations, ""]);
        }
    };

    // Handle removing a destination input
    const removeDestination = (index) => {
        setDestinations(destinations.filter((_, i) => i !== index));
    };

    // Handle change for each destination input
    const handleDestinationChange = (index, value) => {
        const updatedDestinations = [...destinations];
        updatedDestinations[index] = value;
        setDestinations(updatedDestinations);
    };

    const handleTimeChange = (event) => {
        setTime(event.target.value);
    };

    const handleOptionChange = (event) => {
        setSelectedRedio(event.target.value);
    };

    const options = [
        { value: "", label: "Select Car" },
        { value: "saden", label: "Sedan" },
        { value: "suv", label: "Suv" },
        { value: "hatech-back", label: "Hatech Back" },
    ];

    const handleSelectChange = (e) => {
        setCarOption(e.target.value);
    };

    const handleOneWaySearch = (e) => {
        e.preventDefault();

        handleSendOtp(e);

        console.log({
            fromTerm,
            toTerm,
            startDate,
            time,
            carOption,
            passanger,
            phoneNumber,
            offerFrom,
        });
    };

    const handleTwoWaySearch = (e) => {

        handleSendOtp(e);

        console.log({
            fromTerm,
            destination,
            startDate,
            time,
            carOption,
            phoneNumber,
            passanger,
        });
    };

    const handleMultiWaySearch = (e) => {

        handleSendOtp(e);

        console.log({
            fromTerm,
            destinations,
            startDate,
            time,
            carOption,
            phoneNumber,
            passanger,
        });
    };

    const handleSendOtpWithValidation = (e) => {
        const emailValue = emailRef.current.value;
        e.preventDefault(); // Prevent default behavior

        // Validate required fields based on selected radio button
        if (!fromTerm || !phoneNumber || !carOption || !passanger || !emailValue) {
            setError("Please fill all required fields before sending OTP.");
            return; // Exit early if validation fails
        }

        // Clear error message if validation passes
        setError("");

        // Send OTP
        handleSendOtp(e);
    };

    return (
        <>
            <div className="radio-option">
                <div>
                    <input
                        type="radio"
                        value="one-way"
                        checked={selectedRedio === "one-way"}
                        onChange={handleOptionChange}
                    />
                    <label>One&nbsp;Way</label>
                </div>
                <div>
                    <input
                        type="radio"
                        value="round-trip"
                        checked={selectedRedio === "round-trip"}
                        onChange={handleOptionChange}
                    />
                    <label>Round&nbsp;Trip</label>
                </div>
                <div>
                    <input
                        type="radio"
                        value="multi-city"
                        checked={selectedRedio === "multi-city"}
                        onChange={handleOptionChange}
                    />
                    <label>Multi&nbsp;City</label>
                </div>
            </div>
            {activeOtp ? (
                <div className="form-group">
                    <label htmlFor="otp">OTP</label>
                    <OtpVerification
                        numberOfDigits={6}
                        correctOtp={correctOtp}
                        setEnteredOtp={setEnteredOtp}
                    />
                    {msg && <p className="error-message">{msg}</p>}
                </div>
            ) :
                <>
                    <div className="radio-option">
                        <input
                            type="text"
                            placeholder="From"
                            value={fromTerm}
                            onChange={(e) => setFromTerm(e.target.value)}
                            className={`search-input`}
                            required
                        />
                        {selectedRedio === "one-way" && (
                            <input
                                type="text"
                                placeholder="To"
                                value={toTerm}
                                onChange={(e) => setToTerm(e.target.value)}
                                className="search-input"
                                required
                            />
                        )}
                    </div>
                    {selectedRedio === "round-trip" && (
                        <div className="radio-option">
                            <input
                                type="text"
                                placeholder="Destination"
                                value={destination}
                                onChange={(e) => setDestination(e.target.value)}
                                className={`search-input`}
                            />
                        </div>
                    )}
                    {selectedRedio === "multi-city" && (
                        <>
                            {" "}
                            <div className="destinations">
                                {" "}
                                {destinations.map((destination, index) => (
                                    <div key={index} className="destination-input-group">
                                        <input
                                            type="text"
                                            placeholder={`Destination ${index + 1}`}
                                            value={destination}
                                            onChange={(e) =>
                                                handleDestinationChange(index, e.target.value)
                                            }
                                            className="search-input"
                                            required
                                        />
                                        <button
                                            type="button"
                                            onClick={() => removeDestination(index)}
                                            className="remove-button"
                                        >
                                            X
                                        </button>
                                    </div>
                                ))}{" "}
                            </div>
                            <button
                                type="button"
                                onClick={addDestination}
                                disabled={destinations.length >= 10}
                                className="add-button"
                            >
                                <FaPlus /> Add Destination
                            </button>
                        </>
                    )}
                    <div className="radio-option">
                        <DatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            selectsStart
                            startDate={startDate}
                            placeholderText="Start Date"
                            className="date-picker"
                            required
                        />

                        <input
                            className="time-picker"
                            type="time"
                            value={time}
                            onChange={handleTimeChange}
                            required
                        />
                    </div>

                    <div className="radio-option">
                        <select
                            value={carOption}
                            onChange={handleSelectChange}
                            className="select-filter"
                        >
                            {options.map((option) => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </select>

                        <input
                            type="number"
                            placeholder="Passanger"
                            value={passanger}
                            min="1"
                            max="7"
                            onChange={(e) => setPassanger(e.target.value)}
                            className="search-input"
                            required
                        />
                    </div>

                    <div className="radio-option">
                        <input
                            type="text"
                            inputMode="numeric"
                            pattern="[0-9]+"
                            id="phoneNumber"
                            value={phoneNumber}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                            placeholder="Phone Number"
                            className="search-input"
                            required
                        />

                        <input
                            className="search-input"
                            ref={emailRef}
                            type="email"
                            id="email"
                            name="email"
                            placeholder="Enter your eamil"
                            required
                        />
                    </div>

                    {selectedRedio === "one-way" && (
                        <input
                            type="text"
                            placeholder="Offer From"
                            value={offerFrom}
                            onChange={(e) => setOfferFrom(e.target.value)}
                            className="search-input"
                            required
                        />
                    )}
                </>}

                {error && <p className="error-message">{error}</p>}

            {selectedRedio === "round-trip" && (
                <button className="search-button" onClick={activeOtp ? handleOtpSubmit : handleSendOtpWithValidation}>
                    {activeOtp ? "Submit" : <><FaSearch />&nbsp;Search</>}
                </button>
            )}
            {selectedRedio === "one-way" && (
                <button className="search-button" onClick={activeOtp ? handleOtpSubmit : handleSendOtpWithValidation}>
                    {activeOtp ? "Submit" : <><FaSearch />&nbsp;Search</>}
                </button>
            )}

            {selectedRedio === "multi-city" && (
                <button className="search-button" onClick={activeOtp ? handleOtpSubmit : handleSendOtpWithValidation}>
                    {activeOtp ? "Submit" : <><FaSearch />&nbsp;Search</>}
                </button>
            )}
        </>
    );
}

export function TourSearchBar({ navigate }) {
    const [tourFromTerm, setTourFromTerm] = useState("");
    const [destination, setDestination] = useState("");
    const [startDate, setStartDate] = useState(null);
    const [time, setTime] = useState("");
    const [tourOption, setTourOption] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [passanger, setPassanger] = useState("Passanger");

    const tourOptions = [
        { value: "", label: "Select Tour" },
        { value: "Adventure", label: "Adventure" },
        { value: "Wildlife", label: "Wildlife" },
        { value: "Family Package", label: "Family Package" },
        { value: "Honeymoon Package", label: "Honeymoon Package" },
    ];

    const handleTourChanges = (e) => {
        setTourOption(e.target.value);
    };

    const handleTimeChange = (event) => {
        setTime(event.target.value);
    };

    const handleTourPackage = () => {
        const query = {
            tourFromTerm,
            destination,
            time,
            tourOption,
            passanger,
            phoneNumber,
            startDate: startDate ? startDate.toISOString() : null, // Convert date to ISO string if present
        };

        // Use navigate to redirect with state
        navigate("/tour", { state: query });
    };

    return (
        <>
            <div className="radio-option">
                <input
                    type="text"
                    placeholder="From"
                    value={tourFromTerm}
                    onChange={(e) => setTourFromTerm(e.target.value)}
                    className={`search-input`}
                />
            </div>
            <div className="radio-option">
                <input
                    type="text"
                    placeholder="Destination"
                    value={destination}
                    onChange={(e) => setDestination(e.target.value)}
                    className={`search-input`}
                />
            </div>
            <div className="radio-option">
                <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    selectsStart
                    startDate={startDate}
                    placeholderText="Start Date"
                    className="date-picker"
                />

                <input
                    className="time-picker"
                    type="time"
                    value={time}
                    onChange={handleTimeChange}
                />
            </div>

            <div className="radio-option">
                <select
                    value={tourOption}
                    onChange={handleTourChanges}
                    className="select-filter"
                >
                    {tourOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </select>

                <input
                    type="number"
                    placeholder="Passanger"
                    value={passanger}
                    min="1"
                    max="7"
                    onChange={(e) => setPassanger(e.target.value)}
                    className="search-input"
                />
            </div>
            <div className="radio-option">
                <input
                    type="text"
                    inputMode="numeric"
                    pattern="[0-9]+"
                    id="phoneNumber"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    placeholder="Phone Number"
                    className="search-input"
                    required
                />
            </div>
            <button className="search-button" onClick={handleTourPackage}>
                <FaSearch />&nbsp;Search
            </button>
        </>
    );
}

export function GroupSerchBar({ navigate }) {
    const [tourOption, setGroupOption] = useState("");
    const [selectedModel, setSelectedModel] = useState("");

    const groupOptions = [
        { value: "", label: "Select Tour" },
        {
            value: "Adventure",
            label: "Adventure",
            models: ["Mountain", "Desert", "Ice"],
        },
        {
            value: "Wildlife",
            label: "Wildlife",
            models: ["Jungle Sfari", "Rain Forest", "Zoo"],
        },
        { value: "Other", label: "Other" },
    ];

    const handleGroupChanges = (e) => {
        setGroupOption(e.target.value);
        setSelectedModel("");
    };

    const handleModelChange = (e) => {
        setSelectedModel(e.target.value);
    };

    const availbleModels =
        groupOptions.find((option) => option.value === tourOption)?.models || [];

    const handleGroupPackage = () => {
        console.log({
            tourOption,
            selectedModel,
        });

        const query = {
            tourOption,
            selectedModel,
        };

        // Use navigate to redirect with state
        navigate("/group-tour", { state: query });
    };

    return (
        <>
            <div style={{ display: "flex", gap: "1rem" }}>
                <select
                    value={tourOption}
                    onChange={handleGroupChanges}
                    className="select-filter"
                >
                    {groupOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </select>

                <select
                    value={selectedModel}
                    onChange={handleModelChange}
                    disabled={!tourOption}
                    className="select-filter"
                >
                    <option value="">Select Location</option>
                    {availbleModels.map((model, index) => (
                        <option key={index} value={model}>
                            {model}
                        </option>
                    ))}
                </select>
            </div>
            <button className="search-button" onClick={handleGroupPackage}>
                <FaSearch />
                &nbsp;Search
            </button>
        </>
    );
}
