import React, { useState, useEffect, useRef } from "react";
import axios from 'axios';
import '../../styles/pages/authpage.css';

function ForgetPassword({ aciveContainer, setActiveContainer }) {
    const [activeOtp, setActiveOtp] = useState(false);
    const [correctOtp, setCorrectOtp] = useState("");
    const [msg, setMsg] = useState('');
    const [enteredOtp, setEnteredOtp] = useState("");

    // Function to generate a 6-digit OTP
    const generateOtp = () => {
        const range = '0123456789';
        let otpVal = '';
        for (let i = 0; i < 6; i++) {
            otpVal += range[Math.floor(Math.random() * 10)];
        }
        return otpVal;
    };

    const handleSendOtp = async (e) => {
        e.preventDefault();
        const otp = generateOtp();
        setCorrectOtp(otp);
        setActiveOtp(true);
        console.log(`Generated OTP: ${otp}`);

        const email = e.target.elements.email.value;;

        try {
            const response = await axios.post('http://localhost:5000/send-otp', { email, otp });

            if (response.status === 200) {
                alert('OTP sent successfully!');
            } else {
                alert('Failed to send OTP.');
            }
        } catch (error) {
            console.error('Error sending OTP:', error);
            alert('An error occurred.');
        }
    };

    const handleOtpSubmit = (e) => {
        e.preventDefault();
        if (enteredOtp === correctOtp) {
            setMsg("✅ OTP Verified Successfully!");
        } else {
            setMsg("❌ Invalid OTP. Please try again.");
        }
    };

    return (
        <div className={`login-container ${aciveContainer ? 'closeCon' : 'activeCon'}`}>
            <div className="forget-card">
                <h2 className="login-title">Forget Password</h2>
                <form onSubmit={activeOtp ? handleOtpSubmit : handleSendOtp}>
                    <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input
                            className="auth-input"
                            type="email"
                            id="email"
                            name="email"
                            placeholder="Enter your eamil"
                            required
                        />
                    </div>
                    {activeOtp && (
                        <div className="form-group">
                            <label htmlFor="otp">OTP</label>
                            <OtpVerification
                                numberOfDigits={6}
                                correctOtp={correctOtp}
                                setEnteredOtp={setEnteredOtp}
                            />
                        </div>
                    )}
                    {msg && <p className="error-message">{msg}</p>}
                    <button type="submit" className="login-button">
                        {activeOtp ? "Submit" : "Send OTP"}
                    </button>
                </form>
                <p className="forget-password" onClick={() => setActiveContainer(true)}>
                    Back to Login
                </p>
            </div>
        </div>
    );
}

export function OtpVerification({ numberOfDigits, correctOtp, setEnteredOtp }) {
    const [otp, setOtp] = useState(new Array(numberOfDigits).fill(""));
    const otpBoxReference = useRef([]);
    const [otpTimer, setOtpTimer] = useState(60);
    const [isTimerActive, setIsTimerActive] = useState(true);

    useEffect(() => {
        let timer;
        if (isTimerActive && otpTimer > 0) {
            timer = setInterval(() => {
                setOtpTimer((prev) => prev - 1);
            }, 1000);
        } else if (otpTimer === 0) {
            setIsTimerActive(false); // Stop the timer when it reaches zero
        }

        return () => clearInterval(timer); // Cleanup
    }, [isTimerActive, otpTimer]);

    const handleChange = (value, index) => {
        const newOtp = [...otp];
        newOtp[index] = value;
        setOtp(newOtp);
        setEnteredOtp(newOtp.join(""));

        if (value && index < numberOfDigits - 1) {
            otpBoxReference.current[index + 1].focus();
        }
    };

    const handleBackspaceAndEnter = (e, index) => {
        if (e.key === "Backspace" && !e.target.value && index > 0) {
            otpBoxReference.current[index - 1].focus();
        }
        if (e.key === "Enter" && e.target.value && index < numberOfDigits - 1) {
            otpBoxReference.current[index + 1].focus();
        }
    };

    const handleResendOtp = () => {
        if (!isTimerActive) {
            // Call your resend OTP function here
            console.log("OTP Resent");
            setOtpTimer(30); // Reset the timer to 30 seconds
            setIsTimerActive(true); // Start the timer
        }
    };

    return (
        <article>
            <div className="otp-box">
                {otp.map((digit, index) => (
                    <input
                        className="auth-otp"
                        key={index}
                        value={digit}
                        maxLength={1}
                        onChange={(e) => handleChange(e.target.value, index)}
                        onKeyUp={(e) => handleBackspaceAndEnter(e, index)}
                        ref={(ref) => (otpBoxReference.current[index] = ref)}
                    />
                ))}
            </div>
            {isTimerActive ? (
                <p>Resend OTP in {otpTimer} seconds</p>
            ) : (
                <button onClick={handleResendOtp} className="resend-otp-button">
                    Resend OTP
                </button>
            )}
        </article>
    );
}

export default ForgetPassword;
