import React, { useState } from "react";
import { MdOutlineVisibility, MdOutlineVisibilityOff } from "react-icons/md";

function CreateAgent () {

    const [name, setName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [verifyPassword, setVerifyPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [showVerifyPassword, setShowVerifyPassword] = useState(false);
    const [error, setError] = useState("");

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError("");

        if (!email || !password || !name || !phoneNumber) {
            setError("Please fill in all fields.");
            return;
        }

        if (password !== verifyPassword) {
            setError("Passwords do not match.");
            return;
        }

        try {

        } catch (err) {
            setError("Failed to sign up. Please try again.");
            console.error(err);
        }
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const toggleVerifyPasswordVisibility = () => {
        setShowVerifyPassword(!showVerifyPassword);
    };

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="email">Name</label>
                    <input
                        className="auth-input"
                        type="text"
                        id="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        placeholder="Enter your Name"
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="email">Phone Number</label>
                    <input
                        className="auth-input"
                        type="text"
                        inputMode="numeric"
                        pattern="[0-9]+"
                        id="phoneNumber"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        placeholder="Enter your Phone Number"
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                        className="auth-input"
                        type="email"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Enter your email"
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="password">Password</label>
                    <div className="input-icon">
                        <input
                            className="auth-input"
                            type={showPassword ? "text" : "password"}
                            id="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="Enter your password"
                            required
                        />
                        <button
                            type="button"
                            className="password-toggle-btn"
                            onClick={togglePasswordVisibility}
                        >
                            {showPassword ? (
                                <MdOutlineVisibilityOff />
                            ) : (
                                <MdOutlineVisibility />
                            )}
                        </button>
                    </div>
                </div>
                <div className="form-group">
                    <label htmlFor="password">Verify Password</label>
                    <div className="input-icon">
                        <input
                            className="auth-input"
                            type={showVerifyPassword ? "text" : "password"}
                            id="verify-password"
                            value={verifyPassword}
                            onChange={(e) => setVerifyPassword(e.target.value)}
                            placeholder="Enter your password again"
                            required
                        />
                        <button
                            type="button"
                            className="password-toggle-btn"
                            onClick={toggleVerifyPasswordVisibility}
                        >
                            {showVerifyPassword ? (
                                <MdOutlineVisibilityOff />
                            ) : (
                                <MdOutlineVisibility />
                            )}
                        </button>
                    </div>
                </div>
                {error && <p className="error-message">{error}</p>}
                <button type="submit" className="login-button">Done</button>
            </form>
        </div>
    )
}

export default CreateAgent;