import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaHome } from "react-icons/fa";
import { MdOutlineVisibility, MdOutlineVisibilityOff } from "react-icons/md";
import { useClient } from "../../context/UserAuthContext";
import '../../styles/pages/authpage.css';

function SignUpPage() {

    const [isHovered, setIsHovered] = useState(false);
    const [name, setName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [verifyPassword, setVerifyPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [showVerifyPassword, setShowVerifyPassword] = useState(false);
    const [error, setError] = useState("");
    const navigate = useNavigate();

    const { signupUserWithEmailAndPassword } = useClient();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError("");

        if (!email || !password || !name || !phoneNumber) {
            setError("Please fill in all fields.");
            return;
        }

        if (password !== verifyPassword) {
            setError("Passwords do not match.");
            return;
        }

        try {
            await signupUserWithEmailAndPassword(email, password, { name, phoneNumber, verifyPassword}, 'users');
            navigate("/");
        } catch (err) {
            setError("Failed to sign up. Please try again.");
            console.error(err);
        }
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const toggleVerifyPasswordVisibility = () => {
        setShowVerifyPassword(!showVerifyPassword);
    };

    return (
        <div className="signup-customer">
            <div className="login-blur">
                <div className={`login-container`}>
                    <div className={`login-card`}>
                        <a className="back-to-web" href='/' onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}><FaHome />&nbsp;{isHovered && <span className="tooltip-text">Home</span>}</a>
                        <h2 className="login-title">Sign Up</h2>
                        <form onSubmit={handleSubmit}>
                            <div className="form-group">
                                <label htmlFor="email">Name</label>
                                <input
                                    className="auth-input"
                                    type="text"
                                    id="name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    placeholder="Enter your Name"
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="email">Phone Number</label>
                                <input
                                    className="auth-input"
                                    type="text"
                                    inputMode="numeric"
                                    pattern="[0-9]+"
                                    id="phoneNumber"
                                    value={phoneNumber}
                                    onChange={(e) => setPhoneNumber(e.target.value)}
                                    placeholder="Enter your Phone Number"
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="email">Email</label>
                                <input
                                    className="auth-input"
                                    type="email"
                                    id="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    placeholder="Enter your email"
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="password">Password</label>
                                <div className="input-icon">
                                    <input
                                        className="auth-input"
                                        type={showPassword ? "text" : "password"}
                                        id="password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        placeholder="Enter your password"
                                        required
                                    />
                                    <button
                                        type="button"
                                        className="password-toggle-btn"
                                        onClick={togglePasswordVisibility}
                                    >
                                        {showPassword ? (
                                            <MdOutlineVisibilityOff />
                                        ) : (
                                            <MdOutlineVisibility />
                                        )}
                                    </button>
                                </div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="password">Verify Password</label>
                                <div className="input-icon">
                                    <input
                                        className="auth-input"
                                        type={showVerifyPassword ? "text" : "password"}
                                        id="verify-password"
                                        value={verifyPassword}
                                        onChange={(e) => setVerifyPassword(e.target.value)}
                                        placeholder="Enter your password again"
                                        required
                                    />
                                    <button
                                        type="button"
                                        className="password-toggle-btn"
                                        onClick={toggleVerifyPasswordVisibility}
                                    >
                                        {showVerifyPassword ? (
                                            <MdOutlineVisibilityOff />
                                        ) : (
                                            <MdOutlineVisibility />
                                        )}
                                    </button>
                                </div>
                            </div>
                            {error && <p className="error-message">{error}</p>}
                            <button type="submit" className="login-button">Sign In</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SignUpPage;