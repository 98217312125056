
import { useNavigate } from "react-router-dom";
import { useClient } from "../context/UserAuthContext";

function ProfilePage() {

    const navigate = useNavigate();
    const {user, logoutUser } = useClient();

    const handleLogOut = () => {
        logoutUser();
        navigate('/');
    }

    return(
        <div className="layout">
            <div>
            Your Profile Page {user.email}
            </div>
            <button onClick={handleLogOut}>Logout</button>
        </div>
    )
}

export default ProfilePage;